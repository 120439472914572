import { useMemo } from 'react'
import { GetDocumentsModel, useGetDocumentsQuery } from '@/api/products/getDocuments'
import { ResponseDataError } from '@/api/products/getDocuments/adapters/AdapterModel'

export enum GroupType {
  main = 'Main',
  insurance = 'Insurance',
  additional = 'Additional',
}

export type Docs = {
  main: GetDocumentsModel.Document[]
  insurance: GetDocumentsModel.Document[]
  additional: GetDocumentsModel.Document[]
  default: GetDocumentsModel.Document[]
}

export type ErrorData = {
  title: string
  message: string
}

export type Result = {
  isLoading: boolean
  errorData: ErrorData | null
  docs: Docs | null
  notFound: boolean
  isVisibleMain: boolean
  isVisibleInsurance: boolean
  isVisibleAdditional: boolean
}

export const useDocuments = (contractNumber: string, productType: number) => {
  const { data, status, error } = useGetDocumentsQuery({
    contractNumber,
    productType,
  })

  const updatedData: Result = useMemo(() => {
    const isLoading = status === 'loading' || status === 'idle'
    const notFound =
      data?.type === 'ResolveWithData' && (!data?.documents || !data?.documents?.length)
    const errorData = error?.response?.systemErrors[0]
    const isError = status === 'error'

    const result: Result = {
      isLoading,
      notFound: !isLoading && notFound,
      errorData,
      docs: null,
      isVisibleMain: false,
      isVisibleInsurance: false,
      isVisibleAdditional: false,
    }

    if (isError && !result.errorData) {
      result.errorData = {
        title: 'Произошла ошибка',
        message: 'Возникли технические проблемы, повторите попытку позже',
      }
    }

    if (isError || notFound) {
      return result
    }

    if (data?.type === 'ResolveWithData' && !isLoading) {
      const docs: Docs = {
        main: [],
        insurance: [],
        additional: [],
        default: [],
      }
      const updated = data.documents.reduce<Docs>((acc, item) => {
        if (item.GroupType === GroupType.main) {
          acc.main.push(item)
        }
        if (item.GroupType === GroupType.insurance) {
          acc.insurance.push(item)
        }
        if (item.GroupType === GroupType.additional) {
          acc.additional.push(item)
        }
        acc.default.push(item)
        return acc
      }, docs)
      const compareFunc = (a: GetDocumentsModel.Document, b: GetDocumentsModel.Document) => {
        if (a.DisplayOrder > b.DisplayOrder) return 1
        if (a.DisplayOrder < b.DisplayOrder) return -1
        return 0
      }
      return {
        ...result,
        docs: {
          main: updated.main.sort(compareFunc),
          insurance: updated.insurance.sort(compareFunc),
          additional: updated.additional.sort(compareFunc),
          default: updated.default.sort(compareFunc),
        },
        isVisibleMain: updated.main.length > 0,
        isVisibleInsurance: updated.insurance.length > 0,
        isVisibleAdditional: updated.additional.length > 0,
      }
    }
    return result
  }, [data, status])

  return updatedData
}
