import { useEffect } from 'react'
import { ResponseType } from '@/models/ResponseType'
import { GetDepositDetailsModel, useGetDepositDetails } from '@/api/products/getDepositDetails'

export const useDepositDetailsState = ({
  contractUniqueId,
  apiVersion,
}):
  | {
      status: 'idle' | 'loading' | 'error'
      data: null
      retry?: () => void
    }
  | {
      status: 'success'
      data: GetDepositDetailsModel.ResolveWithDataResponse
    } => {
  const { status, data, mutateAsync } = useGetDepositDetails(contractUniqueId)

  useEffect(() => {
    mutateAsync({ contractUniqueId, apiVersion })
  }, [])

  if (status === 'success' && data?.data?.type === ResponseType.ResolveWithData) {
    return {
      status,
      data: data.data,
    }
  }

  return {
    status,
    data: null,
    retry: () => mutateAsync({ contractUniqueId, apiVersion }),
  }
}
