import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { currencyType } from '@/utils/adapter-helpers/currencyType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError
  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   * 401 или 500+ считаем rejected ответами с наличием критической ошибки
   */
  if (errorData?.StatusCode >= 400) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.ErrorDetails
        ? errorData.ErrorDetails.map(({ Error }) => ({
            type: Error,
            message: Error,
          }))
        : [],
    }
    throw HttpClient.createError(errorData?.StatusCode, exception)
  }

  try {
    const successData = data as AdapterModel.ResponseDataSuccess

    const tipsLevelInfo = successData.tipsLevelInfo
      ? Object.values(successData.tipsLevelInfo).map((info) => ({
          ...info,
          currency: currencyType(info.currency).toClient(),
        }))
      : []

    const additionalInfo = successData.additionalInfo
      ? Object.values(successData.additionalInfo)
      : []

    return {
      type: ResponseType.ResolveWithData,
      interestRateTooltipInfo: {
        maxRateTextHtml: successData.maxRateTextHtml,
        maxRate: successData.maxRate,
        tipsLevelInfo: tipsLevelInfo,
        additionalInfo: additionalInfo,
      },
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception)
  }
}
