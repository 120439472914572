import styled from '@emotion/styled'
import { ButtonBase, Shimmer } from '@platform-ui/components'
import { ChevronRightIcon } from '@platform-ui/icons'
import { Body12, Headline16 } from '@platform-ui/typography'

/**
 * ActionBar
 */

export const StyledActionBar = styled.div(({ theme }) => ({
  background: theme.color['background/secondary'],
  borderRadius: theme.borderRadius.r10,
  padding: 16,
  display: 'flex',
  justifyContent: 'center',
  marginTop: 8,
  marginBottom: 16,

  [`${theme.media.md}`]: {
    marginTop: 0,
    padding: 24,
    borderRadius: theme.borderRadius.r14,
    marginBottom: 40,
  },
}))

export const StyledActionButton = styled(ButtonBase)(({ theme }) => ({
  flexDirection: 'column',
  margin: '0 3px',
  maxWidth: 240,
  width: '100%',
  padding: 0,

  ['& > div']: {
    width: 28,
    height: 28,
  },

  [`${theme.media.md}`]: {
    ['& > div']: {
      width: 32,
      height: 32,
    },
  },
}))

export const ActionButtonTitle = styled(Headline16)(({ theme }) => ({
  marginTop: 8,
  fontSize: 12,
  lineHeight: '16px',
  color: theme.color[`text/main`],
  whiteSpace: 'nowrap',
}))

export const ActionIconWrapper = styled.div(() => ({
  'svg > path': {
    strokeWidth: '1.7',
  },
}))

/**
 * MoreActionButtonsContent
 */

export const ActionDialogMoreWrapper = styled(ButtonBase)(({ theme }) => ({
  background: theme.color['surface/main'],
  margin: '2px 0',
  width: '100%',
  padding: '14px 0',

  ':not(:last-child)': {
    borderBottom: `1px solid ${theme.color['surface/bravo']}`,
  },
  ':first-of-type': {
    paddingTop: 0,
  },
}))

export const ActionMoreIcon = styled.div(({ theme }) => ({
  background: theme.color['surface/secondary'],
  borderRadius: theme.borderRadius.r10,
  width: '100%',
  maxWidth: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const ActionMoreTextWrapper = styled.div(() => ({
  padding: '16px 0',
  textAlign: 'left',
  marginLeft: 16,
}))

export const ActionDialogMoreDesc = styled(Body12)(({ theme }) => ({
  color: theme.color['text/secondary'],
  marginTop: 4,
}))

export const ActionDialogMoreRightIcon = styled(ChevronRightIcon)(() => ({
  marginLeft: 'auto',
}))

/**
 * StyledActionBarShimmer
 */

export const ActionBarShimmerWrapper = styled(Shimmer)(({ theme }) => {
  return {
    borderRadius: theme.borderRadius.r10,
    height: 84,
    padding: 16,
    display: 'flex',
    justifyContent: 'center',
    marginTop: 8,
    marginBottom: 16,

    [`${theme.media.md}`]: {
      marginTop: 0,
      padding: 24,
      borderRadius: theme.borderRadius.r14,
      height: 112,
      marginBottom: 40,
    },
  }
})

export const ActionBarShimmerItem = styled.div(() => {
  return {
    maxWidth: 240,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }
})

export const ActionBarShimmerIcon = styled(Shimmer)(({ theme }) => {
  return {
    borderRadius: theme.borderRadius.r10,
    height: 28,
    width: 28,
    backgroundColor: theme.color['background/secondary'],

    [`${theme.media.md}`]: {
      borderRadius: theme.borderRadius.r14,
      height: 32,
      width: 32,
    },
  }
})

export const ActionBarShimmerText = styled(Shimmer)(({ theme }) => {
  return {
    borderRadius: theme.borderRadius.r6,
    height: 12,
    width: 64,
    backgroundColor: theme.color['background/secondary'],
    marginTop: 8,

    [`${theme.media.md}`]: {
      borderRadius: theme.borderRadius.r14,
    },
  }
})
