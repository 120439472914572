import React from 'react'
import { AccountsModel } from '@/api/products/accounts'
import { RecommendationTextModel } from '@/api/products/recommendationText/RecommendationTextModel'
import {
  ProgressBar,
  ProgressBarInner,
  TextWrapper,
  ProgressBarWrapper,
  ProgressBarTextWrapper,
  CurrencyAdditional,
  ProgressBarText,
} from './styled'

interface RateProgressBarProps {
  tipsLevelInfo: RecommendationTextModel.InterestRateTooltipInfo['tipsLevelInfo']
  currency: AccountsModel.Account['currency']
}

export const RateProgressBar: React.FC<RateProgressBarProps> = ({ tipsLevelInfo, currency }) => {
  return (
    <ProgressBarWrapper>
      <ProgressBar>
        <ProgressBarInner
          completed={(tipsLevelInfo[0].amountSpent / tipsLevelInfo[0].amountTarget) * 100}
        />
      </ProgressBar>
      <ProgressBarTextWrapper>
        <TextWrapper position={'start'}>
          <CurrencyAdditional amount={tipsLevelInfo[0].amountSpent} type={currency} />
          <ProgressBarText>{tipsLevelInfo[0].leftProgressBarSign}</ProgressBarText>
        </TextWrapper>
        <TextWrapper position={'end'}>
          <CurrencyAdditional amount={tipsLevelInfo[0].amountLeft} type={currency} />
          <ProgressBarText>{tipsLevelInfo[0].rightProgressBarSign}</ProgressBarText>
        </TextWrapper>
      </ProgressBarTextWrapper>
    </ProgressBarWrapper>
  )
}
