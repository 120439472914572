import styled from '@emotion/styled'
import { Body14, Body16 } from '@platform-ui/typography'

export const SliderInfoWrapper = styled.div(({ theme }) => ({
  marginTop: 0,

  [`${theme.media.md}`]: {
    marginTop: 14,
  },
}))

export const SliderInfoTitle = styled(Body14)(() => ({
  paddingTop: 12,
}))

export const SliderInfoSubTitle = styled(Body16)(({ theme }) => ({
  fontWeight: 600,
  marginTop: 4,

  [`${theme.media.md}`]: {
    fontWeight: 600,
  },
}))
