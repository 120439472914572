import { useQuery, UseQueryOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { GetDocumentsModel } from './GetDocumentsModel'
import { adapter } from './adapters/adapter'

export const getDocuments: GetDocumentsModel.Func = async (props) => {
  const state = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.products.getDocuments,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    GetDocumentsModel.ResponseData,
    void,
    GetDocumentsModel.RequestData,
    GetDocumentsModel.RequestHeaders,
    GetDocumentsModel.ErrorResponse
  >({
    url: state.path,
    headers: null,
    data: {
      contractNumber: props.contractNumber,
      productType: props.productType,
      contractTypeCode: props.contractTypeCode,
    },
    responseAdapter: adapter,
    control: state.control,
  })

  return response
}

function getDocumentsKey(props: GetDocumentsModel.Props) {
  return ['api/Document/GetDocuments', props.contractNumber]
}

export function useGetDocumentsQuery<T extends GetDocumentsModel.ResponseData>(
  props: GetDocumentsModel.Props,
  options: Omit<
    UseQueryOptions<GetDocumentsModel.ResponseData, GetDocumentsModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const key = getDocumentsKey(props)

  const result = useQuery(key, async () => getDocuments({ ...props }).then((res) => res.data), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...options,
  })

  return result
}
