import styled from '@emotion/styled'
import { Shimmer } from '@platform-ui/components'
import { Button } from '@platform-ui/components/Button'

type DocumentTextShimmerProps = {
  textSize: number
}

export const DocumentsWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const ErrorWrapper = styled(DocumentsWrapper)(() => ({
  height: '100%',
  justifyContent: 'space-between',
}))

export const DocumentWrapper = styled.div(({ theme, isLoading }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  padding: '16px 0px',
  width: '100%',
  cursor: isLoading ? 'wait' : 'pointer',
  ':not(:last-child)': {
    borderBottom: '1px solid',
    borderColor: `${theme.color['surface/line']}`,
  },
}))

export const DocumentTextShimmer = styled(Shimmer)<DocumentTextShimmerProps>(({ textSize }) => ({
  width: `${textSize || 160}px`,
  height: '12px',
  borderRadius: '10px',
}))

export const DocumentIconShimmer = styled(Shimmer)(() => ({
  width: '24px',
  height: '24px',
  borderRadius: '8px',
}))

export const DocumentTitleShimmer = styled(Shimmer)<DocumentTextShimmerProps>(({ textSize }) => ({
  width: `${textSize || 160}px`,
  marginTop: '16px',
  marginBottom: '8px',
  height: '16px',
  borderRadius: '10px',
}))

export const CloseButton = styled(Button)(({}) => ({
  width: '100%',
  marginBottom: '16px',
}))
