import { useQuery, UseQueryOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { AccountsModel } from './AccountsModel'
import { adapter } from './adapters/adapter'

export const accounts: AccountsModel.Func = async () => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.products.accounts,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.get<
    AccountsModel.ResponseData,
    void,
    AccountsModel.RequestHeaders,
    AccountsModel.ErrorResponse
  >({
    url: path,
    headers: null,
    responseAdapter: adapter,
    control,
  })

  return response
}

function accountsKey() {
  return '/myc-client-products/v1/api/Accounts'
}

export function useAccountsQuery<T extends AccountsModel.ResponseData = AccountsModel.ResponseData>(
  options: Omit<
    UseQueryOptions<AccountsModel.ResponseData, AccountsModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const key = accountsKey()
  const result = useQuery(key, async () => accounts().then((res) => res.data), {
    ...options,
  })

  return result
}
