import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { MutateOptions, useMutation } from 'react-query'
import { GetDepositDetailsModel } from './GetDepositDetailsModel'
import { adapter } from './adapters/adapter'
import { stringTemplate } from '@/utils/string-template'

export const getDepositDetails: GetDepositDetailsModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.products.getDepositDetails,
    {} as ApiManagerModel.ApiOption
  )

  const url = stringTemplate<{ ContractUniqueId: string }>(path, {
    ContractUniqueId: props.contractUniqueId,
  })

  return await HttpClient.get<
    GetDepositDetailsModel.ResponseData,
    void,
    GetDepositDetailsModel.RequestHeaders,
    GetDepositDetailsModel.ErrorResponse
  >({
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    url,
    responseAdapter: adapter,
    control,
  })
}

export function useGetDepositDetails(
  options?: MutateOptions<
    GetDepositDetailsModel.Response,
    GetDepositDetailsModel.ErrorResponse,
    GetDepositDetailsModel.Props
  >
) {
  return useMutation(getDepositDetails, options)
}
