import { ProductsModel } from '@/api/products/products'
import { useSettingsQuery } from '@/api/settings/settings'
import { ResponseType } from '@/models/ResponseType'
import { formatDate } from '@/utils/formatDate'

export const useIsVisibleConditions = (
  contractStatus: ProductsModel.ContractStatus,
  isPossibilityOfReplenishment: boolean,
  isPossibilityOfPartialWithdrawal: boolean
) => {
  const { data: settingsData } = useSettingsQuery()

  const statusIsActive = contractStatus === ProductsModel.ContractStatus.Active
  const isResolve = settingsData?.type === ResponseType.ResolveWithData

  const isVisibleTopUp = statusIsActive && isPossibilityOfReplenishment
  const isVisibleWithdraw = statusIsActive && isPossibilityOfPartialWithdrawal
  const isVisibleDocuments = isResolve && settingsData.result.enableDocumentsNew

  const isEnableCloseDeposit = isResolve && settingsData.result.enableCloseDeposit
  const startDate = isResolve && settingsData.result.unableCloseDepositStartTime
  const endDate = isResolve && settingsData.result.unableCloseDepositEndTime
  const current = formatDate(new Date(Date.now()), 'HH:mm:ss')
  const isRightTime = current < startDate && current > endDate
  const isVisibleCloseDeposit = statusIsActive && isEnableCloseDeposit && isRightTime

  return {
    isVisibleTopUp,
    isVisibleWithdraw,
    isVisibleDocuments,
    isVisibleCloseDeposit,
  }
}
