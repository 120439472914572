import React from 'react'
import { ActionButton, GroupedActionButtons } from '@/models/ActionBar'

export const useActionBarButtons = (actionButtons: ActionButton[], isMobile: boolean) => {
  const actionBarInfo = React.useMemo(() => {
    const updatedList = actionButtons.filter((btn) => btn.isVisible)
    const maxButtonsQuantity = isMobile ? 2 : 3
    const needMore = updatedList.length > maxButtonsQuantity + 1

    const groupedButtons: GroupedActionButtons = {
      mainButtons: [],
      moreButtons: [],
      needMore,
    }

    if (!needMore) {
      groupedButtons.mainButtons = updatedList
      return groupedButtons
    }
    updatedList.forEach((button, index) => {
      if (index < maxButtonsQuantity) {
        groupedButtons.mainButtons.push(button)
      } else {
        groupedButtons.moreButtons.push(button)
      }
    })
    return groupedButtons
  }, [isMobile, actionButtons])

  return actionBarInfo
}
