import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { AdapterModel } from './AdapterModel'
import { currencyType } from '@/utils/adapter-helpers/currencyType'

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError

  if (errorData?.StatusCode === 401 || errorData?.StatusCode >= 500) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.ErrorDetails
        ? errorData.ErrorDetails.map(({ Error }) => ({
            type: Error,
            message: Error,
          }))
        : [],
    }
    throw HttpClient.createError(errorData?.StatusCode, exception)
  }

  const normalizeContractStatus = (status: string) => {
    const normalizedStatus = status.replaceAll('_', '').toUpperCase()
    return normalizedStatus === 'SCHEDULEDTOCLOSE' ? 'SCHEDULED_TO_CLOSE' : normalizedStatus
  }

  try {
    const successData = data as AdapterModel.ResponseDataSuccess

    const product = {
      ...successData,
      currencyType: currencyType(successData.currency).toClient(),
      contractStatus: normalizeContractStatus(successData.contractStatus),
      lastFourCardDigits: successData.accountNumber.slice(-4),
    }

    return {
      type: ResponseType.ResolveWithData,
      product,
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception, error)
  }
}
