import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { useQuery } from 'react-query'
import { ApiState } from '@/models/ApiState'
import { adapter } from './adapters/adapter'
import { RecommendationTextModel } from './RecommendationTextModel'

export const recommendationTextResponse: RecommendationTextModel.Func = async ({ ...props }) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.products.recommendationText,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    RecommendationTextModel.ResponseData,
    void,
    RecommendationTextModel.RequestData,
    RecommendationTextModel.RequestHeaders,
    RecommendationTextModel.ErrorResponse
  >({
    url: path,
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    data: {
      recommendationTextJson: props.recommendationTextJson,
      accountPrcRate: props.accountPrcRate.toString(),
    },
    responseAdapter: adapter,
    control,
  })

  return response
}

function settingsKey() {
  return '/deposito/v2/recommendationText'
}

export function useRecommendationTextResponseQuery(props: RecommendationTextModel.Props) {
  const key = settingsKey()
  const result = useQuery(
    key,
    async () => await recommendationTextResponse({ ...props }).then((res) => res.data)
  )
  return result
}
