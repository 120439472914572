import { ResponseType } from '@/models/ResponseType'
import { createSelector } from 'reselect'
import { UseQueryResult } from 'react-query'
import { SystemError } from '@/models/SystemError'
import { ApiStatus } from '@/core'
import _keyBy from 'lodash/keyBy'
import { useAccountsQuery, AccountsModel } from '@/api/products/accounts'
import { useWidgetsSettingsQuery, WidgetsSettingsModel } from '@/api/products/widgetsSettings'

interface State {
  accounts: UseQueryResult<AccountsModel.ResponseData, AccountsModel.ErrorResponse>
  widgetsSettings: UseQueryResult<
    WidgetsSettingsModel.ResponseData,
    WidgetsSettingsModel.ErrorResponse
  >
}

const selector = createSelector(
  (state: State) => state.accounts.data,
  (state: State) => state.accounts.status,
  (state: State) => state.accounts.refetch,
  (state: State) => state.widgetsSettings.data,
  (state: State) => state.widgetsSettings.status,
  (
    accountsResponse,
    accountsStatus,
    accountsRefetch,
    widgetsSettingsResponse,
    widgetsSettingsStatus
  ): {
    error: SystemError<'NETWORK' | 'IS_EMPTY_PRODUCTS'> | null
    status: ApiStatus
    retry: () => void
    products
    settings
  } => {
    const retryFns: (() => void)[] = []

    let products = []
    let settings

    let hasNetworkError = false
    let status = ApiStatus.Idle

    if (accountsStatus === 'success' || accountsStatus === 'error') {
      status = ApiStatus.Fulfilled
    }

    if (accountsStatus === 'loading' || widgetsSettingsStatus === 'loading') {
      status = ApiStatus.Pending
    }

    if (accountsStatus === 'error') {
      hasNetworkError = true
    }

    if (accountsStatus === 'error') {
      retryFns.push(accountsRefetch)
    }

    if (accountsResponse && accountsResponse.type === ResponseType.ResolveWithData) {
      products = accountsResponse.accounts
    }

    if (widgetsSettingsResponse && widgetsSettingsResponse.type === ResponseType.ResolveWithData) {
      settings = widgetsSettingsResponse.settings
    }

    const error: SystemError<'NETWORK' | 'IS_EMPTY_PRODUCTS'> | null = hasNetworkError
      ? {
          type: 'NETWORK',
          message: 'При получении данных произошла ошибка',
        }
      : status === ApiStatus.Fulfilled && !products.length
      ? {
          type: 'IS_EMPTY_PRODUCTS',
          message: 'Список продуктов пуст',
        }
      : null

    const productsMap = _keyBy(products, 'id')

    return {
      error: error,
      products: productsMap,
      settings,
      status,
      retry: () => {
        retryFns.forEach((item) => {
          item()
        })
      },
    }
  }
)

export function useGetAccountPageProductsSelector() {
  const accounts = useAccountsQuery()
  const widgetsSettings = useWidgetsSettingsQuery()

  return selector({ accounts, widgetsSettings })
}
