import styled from '@emotion/styled'
import { Body12 } from '@platform-ui/typography'
import { Currency } from '@/components/Currency'

export const ProgressBarWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'flex-end',
  gap: 8,

  [`${theme.media.md}`]: {
    height: 'auto',
  },
}))

export const ProgressBar = styled.div(({ theme }) => ({
  width: '100%',
  height: '8px',
  background: theme.color['surface/bravo'],
  borderRadius: 8,
}))

export const ProgressBarInner = styled.div<{ completed: number }>(({ theme, completed }) => ({
  width: `${completed}%`,
  height: '100%',
  background: theme.color['surface/primary'],
  borderRadius: 8,
}))

export const ProgressBarTextWrapper = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 8,
}))

export const TextWrapper = styled.span<{ position: 'start' | 'end' }>(({ position }) => {
  return {
    maxWidth: 400,
    display: 'inline-block',
    textAlign: `${position}`,
  }
})

export const CurrencyAdditional = styled(Currency)(({ theme }) => {
  return {
    ...theme.typography['body/16'],
    color: theme.color['text/main'],
  }
})

export const ProgressBarText = styled(Body12)<{ isProgressBarText?: boolean }>(
  ({ theme, isProgressBarText }) => ({
    color: theme.color['text/secondary'],

    [theme.media.md]: {
      marginLeft: isProgressBarText ? -8 : 0,
    },
  })
)
