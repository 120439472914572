import React from 'react'
import { observer } from 'mobx-react-lite'
import { Slider, SliderShimmer } from '@/components/Slider'
import { color } from '@platform-ui/core'
import styled from '@emotion/styled'
import { Body14, Body16 } from '@platform-ui/typography'

export const SliderInfoWrapper = styled.div(() => ({
  marginTop: 14,
}))

export const SliderInfoTitle = styled(Body14)(() => ({
  paddingTop: 12,
}))

export const SliderInfoSubTitle = styled(Body16)(({ theme }) => ({
  fontWeight: 600,
  marginTop: 4,

  [`${theme.media.md}`]: {
    fontWeight: 600,
  },
}))

export const AccountSlider = observer(
  ({ isLoading, amount, accountPrcRate, currencyType }: any) => {
    if (isLoading) {
      return <SliderShimmer />
    }

    return (
      <>
        <Slider bgColor={color['products/savingsNew']} amount={amount} currencyType={currencyType}>
          <SliderInfoWrapper>
            <SliderInfoTitle>Процентная ставка</SliderInfoTitle>
            <SliderInfoSubTitle>{accountPrcRate}% годовых</SliderInfoSubTitle>
          </SliderInfoWrapper>
        </Slider>
      </>
    )
  }
)
