import { BaseModal } from '@platform-ui/components'
import { MoreActionButtonsContent } from '@/components/ActionBar'
import { ActiveContent, ButtonsIds } from '../DepositDetails'
import { ActionButton } from '@/models/ActionBar'

type ContentDialogProps = {
  changeActiveTab: (content: ActiveContent) => void
  moreButtons: ActionButton[]
  activeContent: ActiveContent
}

export const ContentDialog: React.FC<React.PropsWithChildren<ContentDialogProps>> = ({
  moreButtons,
  activeContent,
  children,
  changeActiveTab,
}) => {
  const getIsInMore = !!moreButtons.find((button) => button.id === activeContent?.id)

  return (
    <BaseModal
      isOpen={Boolean(activeContent)}
      onClose={() => changeActiveTab(null)}
      title={activeContent?.title || ''}
      subtitle=""
      isBackBtn={getIsInMore}
      backBtnClick={() => changeActiveTab({ id: ButtonsIds.more, title: 'Ещё' })}
    >
      {activeContent?.id === ButtonsIds.more && (
        <MoreActionButtonsContent actionButtons={moreButtons} />
      )}
      {children}
    </BaseModal>
  )
}
