import React from 'react'
import { ContentHeader } from '@/components/ContentHeader'
import { DepositDetails } from '@/features/deposits-details'
import { DepositDetailsSlider } from '@/features/deposits-details/components/DepositDetailsSilder'
import { ContentError } from '@/components/ContentError'
import { useDepositDetailsState } from '../selectors/useDepositDetailsState'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'

type DepositPageProps = {
  productId: string
  contractUniqueId: string
  changedProductName?: string
}

export const DepositPage: React.FC<DepositPageProps> = ({
  productId,
  contractUniqueId,
  changedProductName = null,
}) => {
  const context = useAppContext<AppContext>()
  const { data, status } = useDepositDetailsState({
    contractUniqueId,
    apiVersion: context.config.apiVersion,
  })

  const isLoading = status === 'loading' || status === 'idle'
  const isServerError = status === 'error'
  const isProductNotFound = data?.type === 'ResolveWithData' && !data?.product

  if (isServerError || (!isLoading && isProductNotFound)) {
    return <ContentError />
  }

  return (
    <>
      <ContentHeader
        isLoading={isLoading}
        title={changedProductName ? changedProductName : data?.product?.depositName}
        description={`Вклад • ${data?.product?.lastFourCardDigits}`}
      />
      <DepositDetailsSlider
        isLoading={isLoading}
        amount={data?.product?.runningBalance}
        interestRate={data?.product?.interestRate}
        currencyType={data?.product?.currencyType}
        contractStatus={data?.product?.contractStatus}
        maturityDate={data?.product?.maturityDate}
      />
      <DepositDetails
        isLoading={isLoading}
        productName={data?.product?.depositName}
        currencyType={data?.product?.currencyType}
        lastFourCardDigits={data?.product?.lastFourCardDigits}
        accountNumber={data?.product?.accountNumber}
        contractNumber={data?.product?.contractNumber}
        contractStatus={data?.product?.contractStatus}
        isPossibilityOfReplenishment={data?.product?.isPossibilityOfReplenishment}
        isPossibilityOfPartialWithdrawal={data?.product?.isPossibilityOfPartialWithdrawal}
        productId={productId}
        productVersion={data?.product?.productVersion}
      />
    </>
  )
}
