import { DocsLink } from '../../../components/DocsLink'
import { DocumentButton } from './DocumentButton'
import { GetDocumentsModel } from '@/api/products/getDocuments'

type DocumentProps = {
  name: string
  url?: string
  contractNumber: string
  productType: GetDocumentsModel.DocumentProductType
  reportCode: string
}

export const Document: React.FC<DocumentProps> = ({
  name,
  url,
  contractNumber,
  productType,
  reportCode,
}) => {
  if (url) {
    return <DocsLink title={name} url={url} />
  }

  return (
    <DocumentButton
      title={name}
      contractNumber={contractNumber}
      productType={productType}
      reportCode={reportCode}
    />
  )
}
