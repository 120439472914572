import React, { useState } from 'react'
import { InterestRateModal } from './components/InterestRateModal'
import { Tooltip } from '@platform-ui/components/Tooltip'
import { AppContext } from '@/models/AppContext'
import { Headline24 } from '@platform-ui/typography'
import { useAppContext } from '@/core'
import { InfoPrimaryIcon } from '@platform-ui/icons/InfoPrimaryIcon'
import { InfoIcon } from '@platform-ui/icons/InfoIcon'
import { ChevronRightIcon } from '@platform-ui/icons/ChevronRightIcon'
import { AccountsModel } from '@/api/products/accounts'
import { useRecommendationTextResponseQuery } from '@/api/products/recommendationText/recommendationText'
import { useSettingsQuery } from '@/api/settings/settings'
import { ResponseType } from '@/models/ResponseType'
import {
  TabListWrapper,
  TextAdditional,
  TextSecondary,
  TopContent,
  BottomContent,
  TopLeftContent,
  TooltipText,
  TooltipIcon,
} from './styled'

interface InterestRateProps {
  productById: AccountsModel.Account
}

export const InterestRate: React.FC<InterestRateProps> = ({ productById }) => {
  const { accountPrcRate, recomendationTextJson } = productById
  const isDisplayInterestRate = !!(accountPrcRate && recomendationTextJson)

  if (isDisplayInterestRate) {
    const [isOpenInterestRateModal, setIsOpenInterestRateModal] = useState(false)
    const [isHover, setIsHover] = useState(false)

    const { config } = useAppContext<AppContext>()
    const isMobile = config.device.isMobile

    const { data: settingsData } = useSettingsQuery()
    const isEnableSavingAccountDetailedTooltip =
      settingsData &&
      settingsData.type === ResponseType.ResolveWithData &&
      settingsData.result.EnableSavingAccountDetailedTooltip

    const recommendationTextJson = recomendationTextJson
    const isJsonDataAvailable =
      recommendationTextJson && Object.keys(JSON.parse(recommendationTextJson)).length !== 0

    const { data: recommendationText } = useRecommendationTextResponseQuery({
      recommendationTextJson,
      accountPrcRate,
    })

    const widgetTextHtml =
      recommendationText &&
      recommendationText.type === ResponseType.ResolveWithData &&
      recommendationText.interestRateTooltipInfo.tipsLevelInfo[0]?.widgetTextHtml

    const isSimpleHint = !isEnableSavingAccountDetailedTooltip || !isJsonDataAvailable

    return (
      <TabListWrapper isSimpleHint={isSimpleHint}>
        <TopContent onClick={() => !isSimpleHint && setIsOpenInterestRateModal(true)}>
          <TopLeftContent>
            <Headline24>{accountPrcRate}% годовых</Headline24>
            {isSimpleHint && (
              <Tooltip
                title={<TooltipText>{productById.recomendationText}</TooltipText>}
                placement={isMobile ? 'bottom' : 'bottom-start'}
                isArrow={false}
                paperProps={{ backgroundColor: 'surface/alpha' }}
              >
                <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
                  {isHover ? (
                    <InfoPrimaryIcon size="s12" color="icons/secondary" />
                  ) : (
                    <TooltipIcon>
                      <InfoIcon size="s10" />
                    </TooltipIcon>
                  )}
                </div>
              </Tooltip>
            )}
          </TopLeftContent>
          {!isSimpleHint && <ChevronRightIcon size="s16" color="text/main" />}
        </TopContent>

        <TextAdditional>Процент на остаток</TextAdditional>
        {widgetTextHtml && (
          <BottomContent>
            <TextSecondary
              dangerouslySetInnerHTML={{
                __html: widgetTextHtml,
              }}
            />
          </BottomContent>
        )}
        {recommendationText && (
          <InterestRateModal
            isOpen={isOpenInterestRateModal}
            onClose={() => setIsOpenInterestRateModal(false)}
            interestRateTooltipInfo={recommendationText.interestRateTooltipInfo}
            accountPrcRate={productById.accountPrcRate}
          />
        )}
      </TabListWrapper>
    )
  } else {
    return null
  }
}
