import { ErrorDataLoadingModal } from '@/components/ErrorDataLoadingModal'
import { useDocuments } from './useDocuments'
import { Document } from './components/Document'
import { CloseButton, DocumentsWrapper, ErrorWrapper } from './styled'
import { GetDocumentsModel } from '@/api/products/getDocuments'
import { DocumentsShimmer } from './components/DocumentsShimmer'
import { DocumentTitle } from '@/components/DocsLink/styled'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'

export type DocumentsProps = {
  contractNumber: string
  productType: number
  handleClose: () => void
}

export const Documents: React.FC<DocumentsProps> = ({
  contractNumber,
  productType,
  handleClose,
}) => {
  const { config } = useAppContext<AppContext>()
  const isMobile = config.device.isMobile

  const {
    errorData,
    isLoading,
    notFound,
    docs,
    isVisibleAdditional,
    isVisibleInsurance,
    isVisibleMain,
  } = useDocuments(contractNumber, productType)

  const getDocumentProps = (document: GetDocumentsModel.Document) => ({
    key: document.ViewName,
    name: document.ViewName,
    url: document.DocumentUrl,
    contractNumber: contractNumber,
    productType: productType,
    reportCode: document.ServiceName,
  })

  if (isLoading) {
    return <DocumentsShimmer isMobile={isMobile} />
  }

  if (errorData) {
    return (
      <ErrorWrapper>
        <ErrorDataLoadingModal title={errorData.title} description={errorData.message} />
        <CloseButton onClick={handleClose}>Закрыть</CloseButton>
      </ErrorWrapper>
    )
  }

  if (docs) {
    return (
      <DocumentsWrapper>
        <div>
          {docs.default.map((doc) => (
            <Document
              key={doc.ViewName}
              name={doc.ViewName}
              url={doc.DocumentUrl}
              contractNumber={contractNumber}
              productType={productType}
              reportCode={doc.ServiceName}
            />
          ))}
        </div>
        {isVisibleMain && (
          <div>
            <DocumentTitle>Документы по договору</DocumentTitle>
            {docs.main.map((doc) => (
              <Document key={doc.ViewName} {...getDocumentProps(doc)} />
            ))}
          </div>
        )}
        {isVisibleInsurance && (
          <div>
            <DocumentTitle>Страховые документы</DocumentTitle>
            {docs.insurance.map((doc) => (
              <Document key={doc.ViewName} {...getDocumentProps(doc)} />
            ))}
          </div>
        )}
        {isVisibleAdditional && (
          <div>
            <DocumentTitle>Прочие документы</DocumentTitle>
            {docs.additional.map((doc) => (
              <Document key={doc.ViewName} {...getDocumentProps(doc)} />
            ))}
          </div>
        )}
      </DocumentsWrapper>
    )
  }

  if (notFound) {
    return (
      <ErrorWrapper>
        <ErrorDataLoadingModal
          isWarning
          title="Документы не найдены"
          description="В выбранном продукте пока нет документов"
        />
        <CloseButton onClick={handleClose}>Закрыть</CloseButton>
      </ErrorWrapper>
    )
  }
}
