import React from 'react'
import _uniqueId from 'lodash/uniqueId'

import {
  ActionBarShimmerIcon,
  ActionBarShimmerItem,
  ActionBarShimmerText,
  ActionBarShimmerWrapper,
} from './styled'

type ActionBarShimmerProps = {
  isMobile: boolean
}

export const ActionBarShimmer: React.FC<ActionBarShimmerProps> = ({ isMobile }) => {
  return (
    <ActionBarShimmerWrapper>
      {Array.from({ length: isMobile ? 3 : 4 }).map(() => (
        <ActionBarShimmerItem key={_uniqueId()}>
          <ActionBarShimmerIcon />
          <ActionBarShimmerText />
        </ActionBarShimmerItem>
      ))}
    </ActionBarShimmerWrapper>
  )
}
