import { HttpClientModel } from '@/core'
import { ResolveWithSystemErrorResponse } from '@/models/ResolveWithSystemErrorResponse'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'

export namespace GetDocumentsModel {
  export type Func = (props: RequestData) => Promise<Response>
  export type Props = {
    contractNumber: string
    productType: DocumentProductType
    contractTypeCode?: number
  }
  export type Response = HttpClientModel.Response<ResponseData>
  export type ErrorResponse = HttpClientModel.ErrorResponse<ErrorResponseData>
  export type ErrorResponseData = RejectedWithSystemErrorResponse
  export interface ResolveWithDataResponse {
    type: ResponseType.ResolveWithData
    documents: Document[]
  }

  export type RequestData = {
    contractNumber: string
    productType: DocumentProductType
    contractTypeCode?: number
  }

  export enum DocumentProductType {
    ss = 1,
    sc = 2,
    rd = 3,
    tw = 7,
    dc = 5,
    deposit = 8,
    annuityDeposit = 16,
  }

  export type Document = {
    ProductType: DocumentProductType
    Type: string
    ViewName: string
    DisplayOrder: number
    DocumentUrl?: string
    ServiceName?: string
    GroupType?: string
  }

  export type ResponseData = ResolveWithDataResponse | ResponseDataResolveWithSystemErrorResponse
  export type ResponseDataResolveWithSystemErrorResponse = ResolveWithSystemErrorResponse
  export type RequestHeaders = null
}
