import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { adapter } from './adapters/adapter'
import { GetDocumentFileModel } from './GetDocumentFileModel'

export const getDocumentFile: GetDocumentFileModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.products.getDocumentFile,
    {} as ApiManagerModel.ApiOption
  )

  return await HttpClient.get<
    GetDocumentFileModel.ResponseData,
    GetDocumentFileModel.Props,
    GetDocumentFileModel.RequestHeaders,
    GetDocumentFileModel.ErrorResponse
  >({
    url: path,
    headers: null,
    query: {
      contractNumber: props.contractNumber,
      productType: props.productType,
      reportCode: props.reportCode,
    },
    responseAdapter: adapter,
    responseType: 'arraybuffer',
    control,
  })
}
