import {
  DocumentIconShimmer,
  DocumentTextShimmer,
  DocumentTitleShimmer,
  DocumentWrapper,
  DocumentsWrapper,
} from '../styled'

type DocumentShimmerProps = {
  textSize: number
}

type DocumentsShimmerProps = {
  isMobile: boolean
}

const DocumentShimmer: React.FC<DocumentShimmerProps> = ({ textSize }) => (
  <DocumentWrapper isLoading>
    <DocumentIconShimmer />
    <DocumentTextShimmer textSize={textSize} />
  </DocumentWrapper>
)

export const DocumentsShimmer: React.FC<DocumentsShimmerProps> = ({ isMobile }) => {
  return (
    <>
      <DocumentsWrapper>
        <DocumentShimmer textSize={110} />
        <DocumentShimmer textSize={isMobile ? 171 : 208} />
      </DocumentsWrapper>
      <DocumentsWrapper>
        <DocumentTitleShimmer textSize={isMobile ? 248 : 162} />
      </DocumentsWrapper>
      <DocumentsWrapper>
        <DocumentShimmer textSize={isMobile ? 162 : 179} />
        <DocumentShimmer textSize={isMobile ? 79 : 160} />
      </DocumentsWrapper>
    </>
  )
}
