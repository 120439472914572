import styled from '@emotion/styled'
import { Body14 } from '@platform-ui/typography'

export const DialogContainer = styled.div(({ theme }) => ({
  height: 'calc(100% - 80px)',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',

  [`${theme.media.md}`]: {
    height: 'calc(680px - 80px)',
  },
}))

export const TopContent = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  background: 'inherit',
  width: '100%',
}))

export const TitleText = styled.p(({ theme }) => ({
  ...theme.typography['headline/16'],
  color: theme.color['text/main'],

  [`${theme.media.md}`]: {
    ...theme.typography['headline/20'],
    color: theme.color['text/main'],
  },
}))

export const SecondaryText = styled(Body14)(() => ({
  display: 'flex',
  textAlign: 'start',
  fontWeight: '500',
  alignContent: 'start',
  p: { margin: 0 },
  customspan: { fontWeight: '600' },
}))

export const CurrentInterest = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'flex-end',
  paddingBottom: 20,
  borderBottom: `1px solid ${theme.color['surface/bravo']}`,
  gap: 16,

  '&:not(:first-of-type)': {
    margin: '16px 0 0 0',
  },

  [`${theme.media.md}`]: {
    height: 'auto',
  },
}))

export const WithProgressBar = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'flex-end',
  padding: '20px 0',
  borderBottom: `1px solid ${theme.color['surface/bravo']}`,
  gap: 16,

  [`${theme.media.md}`]: {
    height: 'auto',
  },
}))

export const ConditionsWrapper = styled.ul(({ theme }) => ({
  padding: '20px 12px',
  margin: '20px 0',
  background: theme.color['surface/tertiary'],
  borderRadius: 16,
  zIndex: 2,

  [`${theme.media.md}`]: {
    padding: '20px',
  },
}))

export const Conditions = styled.div(() => ({
  padding: '0 0 0 20px',
}))

export const ConditionItem = styled.li(({ theme }) => ({
  textAlign: 'start',
  fontWeight: 500,
  p: {
    padding: 0,
    margin: '14px 0 0 0',
    lineHeight: '24px',
  },
  a: { textDecoration: 'none', color: theme.color['text/primary'] },
}))
