import { HttpClientModel } from '@/core'
import { ResponseType } from '@/models/ResponseType'
import { ResolveWithSystemErrorResponse } from '@/models/ResolveWithSystemErrorResponse'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { CurrencyType } from '@/models/CurrencyType'

export namespace GetDepositDetailsModel {
  export type Func = (props: Props) => Promise<Response>
  export interface Props {
    contractUniqueId: string
    apiVersion: string
  }
  export type Response = HttpClientModel.Response<ResponseData>
  export type ErrorResponse = HttpClientModel.ErrorResponse<ErrorResponseData>
  export type ResolveWithDataResponse = {
    type: ResponseType.ResolveWithData
    product: DepositDetails
  }

  export interface DepositDetails {
    accountNumber: string
    contractNumber: string
    systemCode: string
    depositType: string
    contractStatus: string
    startDate: string
    maturityDate: string
    runningBalance: number
    currency: string
    currencyCode: string
    isPossibilityOfReplenishment: boolean
    isPossibilityOfPartialWithdrawal: boolean
    contractUniqueId: string
    isExpiredData: number
    lastRolloverDate: string
    originalAmount: number
    accruedInterest: number
    daysTerm: number
    monthsTerm: number
    daysBeforeMaturity: number
    interestRate?: number
    minimumBalance: number
    isPossibilityOfWithdrawal: boolean
    isPossibilityOfRollover: boolean
    relatedAccounts: {
      accountNumber: string
      accountName: string
      runningBalance: number
      currency: string
    }[]
    productVersion: string
    currencyType: CurrencyType
    lastFourCardDigits: string
  }

  export enum ContractStatus {
    Unknown = 'Unknown',
    Active = 'Active',
    ScheduledToClose = 'ScheduledToClose',
    Closed = 'Closed',
  }

  export type ResponseData = ResolveWithDataResponse | ResolveWithSystemErrorResponse
  export type ErrorResponseData = RejectedWithSystemErrorResponse
  export type RequestData = null
  export interface RequestHeaders {
    _ver_: string
    _os_: string
  }
}
