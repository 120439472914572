import React from 'react'
import { useRouter } from '@/next//router'
import { ApiStatus } from '@/core'
import { ContentError } from '@/components/ContentError'
import { ContentHeader } from '@/components/ContentHeader'
import { AccountSlider } from '@/features/account-slider'
import { AccountDetails } from '@/features/account-details'
import { useGetAccountPageProductsSelector } from '../selectors/useGetAccountPageProductsSelector'

export const SavingAccountPage = () => {
  const router = useRouter()
  const productId = router.query.productId as string
  // необходимо будет заменить селектор когда будет реализовано получение данных по конкретному продукту с помощью идентификатора
  const { status, products, settings } = useGetAccountPageProductsSelector()

  const isLoading = status === ApiStatus.Pending
  const isServerError = status === ApiStatus.Rejected
  const isProductNotFound = status === ApiStatus.Fulfilled && !products[productId]

  if (isServerError || isProductNotFound) {
    return <ContentError />
  }

  const productById = products[productId]
  const contractNumber = !isLoading && productById.contractNumber

  const changedProductName =
    !isLoading &&
    settings &&
    settings[contractNumber] &&
    settings[contractNumber].changedProductName
  const productName = !isLoading && productById.accountName
  const description = !isLoading && `Счёт • ${productById.lastFourCardDigits}`

  const accountPrcRate = !isLoading && productById.accountPrcRate
  const amount = !isLoading && productById.runningBalance

  const accountNumber = !isLoading && productById.accountNumber
  const lastFourCardDigits = !isLoading && productById.lastFourCardDigits
  const currencyType = !isLoading && productById.currency

  return (
    <>
      <ContentHeader
        isLoading={isLoading}
        title={changedProductName || productName}
        description={description}
      />
      <AccountSlider
        isLoading={isLoading}
        amount={amount}
        accountPrcRate={accountPrcRate}
        currencyType={currencyType}
      />
      <AccountDetails
        isLoading={isLoading}
        accountNumber={accountNumber}
        contractNumber={contractNumber}
        lastFourCardDigits={lastFourCardDigits}
        productName={productName}
        currencyType={currencyType}
        productId={productId}
        productById={productById}
      />
    </>
  )
}
