import React, { useState } from 'react'
import { color } from '@platform-ui/core'
import {
  DepositSecondaryIcon,
  DocsIcon,
  DocsSecondaryIcon,
  MoneyIcon,
  InfoSecondaryIcon,
  CircleCloseIcon,
} from '@platform-ui/icons'
import { TIconProps } from '@platform-ui/icons/core/Icon.types'

import { History } from '@/features/history'
import { ActionBar, ActionBarShimmer } from '@/components/ActionBar'
import { ProductInDevelopmentDialog } from '../product-lists/components/ProductInDevelopmentDialog'
import { ContentDialog } from './components/ContentDialog'
import { useAppContext } from '@/core'
import { useActionBarButtons } from '@/hooks/useActionBarButtons'
import { ProductsModel } from '@/api/products/products'
import { AppContext } from '@/models/AppContext'
import { CurrencyType } from '@/models/CurrencyType'
import { useIsVisibleConditions } from './useIsVisibleConditions'
import { Documents } from '../documents/Documents'
import { GetDocumentsModel } from '@/api/products/getDocuments'

export enum ButtonsIds {
  more = 'more',
  topUp = 'topup',
  withdraw = 'withdraw',
  details = 'details',
  requisites = 'requisites',
  docs = 'docs',
  close = 'close',
}

export type ActiveContent = {
  id: string | null
  title: string
} | null

type DepositDetailsProps = {
  productId: string
  isLoading?: boolean
  accountNumber?: string
  contractNumber?: string
  lastFourCardDigits?: string
  productName?: string
  currencyType?: CurrencyType
  contractStatus?: ProductsModel.ContractStatus
  isPossibilityOfReplenishment?: boolean
}

export const DepositDetails: React.FC<DepositDetailsProps> = ({
  productId,
  isLoading,
  accountNumber,
  contractNumber,
  lastFourCardDigits,
  productName,
  currencyType,
  contractStatus,
  isPossibilityOfReplenishment,
  isPossibilityOfPartialWithdrawal,
  productVersion,
}) => {
  const { config } = useAppContext<AppContext>()
  const isMobile = config.device.isMobile

  const [isOpenInDevelopmentModal, setIsOpenInDevelopmentModal] = useState(false)
  const [activeContent, setActiveContent] = useState<ActiveContent>(null)

  const iconProps: TIconProps = {
    color: 'icons/primary',
    size: isMobile ? 's12' : 's16',
  }
  const { isVisibleTopUp, isVisibleDocuments, isVisibleCloseDeposit, isVisibleWithdraw } =
    useIsVisibleConditions(
      contractStatus,
      isPossibilityOfReplenishment,
      isPossibilityOfPartialWithdrawal
    )

  const temporaryAction = () => setIsOpenInDevelopmentModal(true)
  const changeActiveTab = (activeContent: ActiveContent) => setActiveContent(activeContent)

  const buttonsList = [
    {
      id: ButtonsIds.topUp,
      title: 'Пополнить',
      description: 'Пополнить',
      isVisible: isVisibleTopUp,
      icon: <DepositSecondaryIcon {...iconProps} />,
      action: temporaryAction,
    },
    {
      id: ButtonsIds.withdraw,
      title: 'Снять',
      description: 'Снять',
      isVisible: isVisibleWithdraw,
      icon: <MoneyIcon {...iconProps} />,
      action: temporaryAction,
    },
    {
      id: ButtonsIds.details,
      title: 'Подробнее',
      description: 'Основная информация о вкладе',
      isVisible: true,
      icon: <InfoSecondaryIcon {...iconProps} />,
      action: temporaryAction,
    },
    {
      id: ButtonsIds.requisites,
      title: 'Реквизиты',
      description: 'Номер счёта, БИК, ИНН и другие банковские реквизиты',
      isVisible: false,
      icon: <DocsIcon {...iconProps} />,
      action: temporaryAction,
    },
    {
      id: ButtonsIds.docs,
      title: 'Документы',
      description: 'Все документы, подписанные вами онлайн',
      isVisible: isVisibleDocuments,
      icon: <DocsSecondaryIcon {...iconProps} />,
      action: () => setActiveContent({ id: ButtonsIds.docs, title: 'Документы' }),
    },
    {
      id: ButtonsIds.close,
      title: 'Закрыть вклад',
      description: 'Деньги будут переведены на привязанную карту завтра',
      isVisible: isVisibleCloseDeposit,
      icon: <CircleCloseIcon {...iconProps} />,
      action: temporaryAction,
    },
  ]

  const { mainButtons, moreButtons, needMore } = useActionBarButtons(buttonsList, isMobile)

  const productType =
    productVersion === 'AnnuityDeposit'
      ? GetDocumentsModel.DocumentProductType.annuityDeposit
      : GetDocumentsModel.DocumentProductType.deposit

  return (
    <>
      {isLoading ? (
        <ActionBarShimmer isMobile={isMobile} />
      ) : (
        <ActionBar
          mainButtons={mainButtons}
          moreButtonAction={
            needMore ? () => setActiveContent({ id: ButtonsIds.more, title: 'Ещё' }) : null
          }
        />
      )}
      <ProductInDevelopmentDialog
        isOpen={isOpenInDevelopmentModal}
        onClose={() => setIsOpenInDevelopmentModal(false)}
      />
      <ContentDialog
        changeActiveTab={changeActiveTab}
        moreButtons={moreButtons}
        activeContent={activeContent}
      >
        <>
          {activeContent?.id === ButtonsIds.docs && (
            <Documents
              contractNumber={contractNumber}
              productType={productType}
              handleClose={() => setActiveContent(null)}
            />
          )}
        </>
      </ContentDialog>

      {Boolean(accountNumber) && (
        <History
          productType="deposit"
          accountNumber={accountNumber}
          contractNumber={contractNumber}
          lastFourCardDigits={lastFourCardDigits}
          productName={productName}
          startContractDate="2021-02-01"
          bgColor={color['products/deposits']}
          currencyType={currencyType}
        />
      )}
    </>
  )
}
