import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { TabList } from '@/components/TabList'
import { DepositSecondaryIcon } from '@platform-ui/icons/DepositSecondaryIcon'
import { DocsIcon } from '@platform-ui/icons/DocsIcon'
import MoneyIcon from './assets/money-icon.svg'
import Image from '@/next//image'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { ProductInDevelopmentDialog } from '@/features/product-lists/components/ProductInDevelopmentDialog'
import { TRANSACTION_TYPE } from '@/features/transfers-list/constants'

export enum TabListId {
  Deposit = 'deposit',
  Withdraw = 'withdraw',
  Details = 'details',
}

export const getTabList = (isMobile: boolean) => {
  const tabList: {
    id: string
    title: string
    icon: React.ReactNode
    parentTabId: string
  }[] = [
    {
      id: TabListId.Deposit,
      title: 'Пополнить',
      icon: <DepositSecondaryIcon color="icons/primary" />,
      parentTabId: '',
    },
    {
      id: TabListId.Withdraw,
      title: 'Снять',
      icon: (
        <Image src={MoneyIcon} width={isMobile ? 28 : 32} height={isMobile ? 28 : 32} alt="Money" />
      ),
      parentTabId: '',
    },
    {
      id: TabListId.Details,
      title: 'Реквизиты',
      icon: <DocsIcon color="icons/primary" />,
      parentTabId: '',
    },
  ]

  return {
    tabList,
  }
}

export interface TabBarProps {
  productId: string
}

export const TabBar = observer(({ productId }: TabBarProps) => {
  const { config, eventBus } = useAppContext<AppContext>()
  const isMobile = config.device.isMobile
  const [isOpenInDevelopmentModal, setIsOpenInDevelopmentModal] = useState(false)

  const { tabList } = getTabList(isMobile)

  const onOpen = (id: string) => () => {
    if (id === TabListId.Deposit) {
      eventBus.transfersModal.open.emit({
        productId,
        type: TRANSACTION_TYPE.betweenAccounts,
        isHaveInitTo: true,
      })
      return
    }

    if (id === TabListId.Withdraw) {
      eventBus.payListModal.open.emit({ id: productId })
      return
    }
    if (id === TabListId.Details) {
      setIsOpenInDevelopmentModal(true)
    }
  }
  return (
    <>
      <ProductInDevelopmentDialog
        isOpen={isOpenInDevelopmentModal}
        onClose={() => setIsOpenInDevelopmentModal(false)}
      />
      <TabList data={tabList} onOpen={onOpen} />
    </>
  )
})
