import { HttpClientModel } from '@/core'
import { ResolveWithSystemErrorResponse } from '@/models/ResolveWithSystemErrorResponse'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { CurrencyType } from '@/models/CurrencyType'

export namespace AccountsModel {
  export type Func = () => Promise<Response>
  export type Response = HttpClientModel.Response<ResponseData>
  export type ErrorResponse = HttpClientModel.ErrorResponse<ErrorResponseData>
  export type ErrorResponseData = RejectedWithSystemErrorResponse
  export interface ResolveWithDataResponse {
    type: ResponseType.ResolveWithData
    accounts: Account[]
    isExpired: boolean
  }

  export interface Account {
    id: string
    lastFourCardDigits: string
    accountNumber: string
    contractNumber: string
    accountName: string
    runningBalance: number
    status: AccountsModel.AccountStatus
    accountType: AccountsModel.AccountType
    currency: CurrencyType
    currencyCode: number
    accountPrcRate: number
    recomendationText: string
    recomendationTextJson?: string
    displayOrder: number
  }

  export enum AccountStatus {
    Inactive = 'Inactive',
    Open = 'Open',
    Deposit = 'Deposit',
    OpenPrimaryAccount = 'OpenPrimaryAccount',
    DepositOnlyPrimaryAccount = 'DepositOnlyPrimaryAccount',
    InformationOnly = 'InformationOnly',
    Closed = 'Closed',
    Other = 'Other',
  }

  export enum AccountType {
    Other = 'Other',
    Credit = 'Credit',
    DebitCard = 'DebitCard',
    CreditCard = 'CreditCard',
    ShoppingCard = 'ShoppingCard',
    DemandAccTw = 'DemandAccTw',
    DemandAccQrm = 'DemandAccQrm',
    CarLoan = 'CarLoan',
    Mortgage = 'Mortgage',
    NominalAcc = 'NominalAcc',
    SavingAccount = 'SavingAccount',
  }

  export type ResponseData = ResolveWithDataResponse | ResponseDataResolveWithSystemErrorResponse
  export type ResponseDataResolveWithSystemErrorResponse = ResolveWithSystemErrorResponse
  export type RequestHeaders = null
}
