import { Headline16 } from '@platform-ui/typography'
import { ActionButton } from '@/models/ActionBar'
import {
  ActionDialogMoreDesc,
  ActionDialogMoreRightIcon,
  ActionDialogMoreWrapper,
  ActionMoreIcon,
  ActionMoreTextWrapper,
} from './styled'

type MoreActionButtonsContentProps = {
  actionButtons: ActionButton[]
}

export const MoreActionButtonsContent: React.FC<MoreActionButtonsContentProps> = ({
  actionButtons,
}) => {
  return (
    <>
      {actionButtons.map((button) => (
        <ActionDialogMoreWrapper
          key={button.id}
          onClick={() => button.action({ id: button.id, title: button.title })}
        >
          <ActionMoreIcon>{button.icon}</ActionMoreIcon>
          <ActionMoreTextWrapper>
            <Headline16>{button.title}</Headline16>
            <ActionDialogMoreDesc>{button.description}</ActionDialogMoreDesc>
          </ActionMoreTextWrapper>
          <ActionDialogMoreRightIcon color="icons/main" />
        </ActionDialogMoreWrapper>
      ))}
    </>
  )
}
