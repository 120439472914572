import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { currencyType } from '@/utils/adapter-helpers/currencyType'
import { createAccountId } from '@/utils/adapter-helpers/createAccountId'

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError

  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   * 401 или 500+ считаем rejected ответами с наличием критической ошибки
   */
  if (errorData?.statusCode === 401 || errorData?.statusCode >= 500) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.errorDetails
        ? errorData.errorDetails.map(({ error }) => ({
            type: error,
            message: error,
          }))
        : [],
    }
    throw HttpClient.createError(errorData?.statusCode, exception)
  }

  try {
    /**
     * Есть ошибки от сервера
     */
    if (errorData.errorDetails && errorData.errorDetails.length) {
      return {
        type: ResponseType.ResolveWithSystemError,
        systemErrors: errorData.errorDetails.map(({ error }) => ({
          type: error,
          message: error,
        })),
      }
    }

    const successData = data as AdapterModel.ResponseDataSuccess

    return {
      type: ResponseType.ResolveWithData,
      isExpired: successData.isExpired,
      accounts: successData.accounts.map((account) => ({
        id: createAccountId(account.accountNumber),
        lastFourCardDigits: account.accountNumber.slice(-4),
        accountNumber: account.accountNumber,
        contractNumber: account.contractNumber,
        accountName: account.accountName,
        runningBalance: account.runningBalance,
        status: account.status,
        accountType: account.accountType,
        currency: currencyType(account.currency).toClient(),
        currencyCode: account.currencyCode,
        accountPrcRate: account.accountPrcRate,
        recomendationText: account.recomendationText,
        recomendationTextJson: account.recomendationTextJson,
        displayOrder: account.displayOrder,
      })),
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception, error)
  }
}
