import React from 'react'
import { BaseModal } from '@platform-ui/components'
import { SuccessIcon } from '@platform-ui/icons/SuccessIcon'
import { RecommendationTextModel } from '@/api/products/recommendationText/RecommendationTextModel'
import { RateProgressBar } from '../RateProgressBar'
import { uniqueId } from 'lodash'
import {
  Conditions,
  ConditionItem,
  CurrentInterest,
  ConditionsWrapper,
  DialogContainer,
  WithProgressBar,
  SecondaryText,
  TopContent,
  TitleText,
} from './styled'

export interface InterestRateModalProps {
  isOpen: boolean
  onClose: () => void
  accountPrcRate: number
  interestRateTooltipInfo: RecommendationTextModel.ResponseData['interestRateTooltipInfo']
}

export const InterestRateModal: React.FC<InterestRateModalProps> = ({
  onClose,
  isOpen,
  accountPrcRate,
  interestRateTooltipInfo,
}) => {
  const { tipsLevelInfo, maxRateTextHtml, additionalInfo } = interestRateTooltipInfo

  return (
    <BaseModal isOpen={isOpen} onClose={onClose} title={'Процентная ставка'}>
      <DialogContainer>
        <CurrentInterest>
          <TopContent>
            <TitleText>{accountPrcRate}% годовых</TitleText>
            <SuccessIcon color="icons/colors/success" size="s12" />
          </TopContent>
          <SecondaryText>
            {tipsLevelInfo?.length ? (
              'Ваш текущий процент на остаток'
            ) : (
              <SecondaryText dangerouslySetInnerHTML={{ __html: maxRateTextHtml }} />
            )}
          </SecondaryText>
        </CurrentInterest>

        {tipsLevelInfo?.length ? (
          <>
            <WithProgressBar>
              <TopContent>
                <TitleText>{tipsLevelInfo[0].targetRate}% годовых</TitleText>
              </TopContent>
              <SecondaryText dangerouslySetInnerHTML={{ __html: tipsLevelInfo[0].textHtml }} />
              {tipsLevelInfo[0].amountSpent !== 0 && (
                <RateProgressBar
                  currency={tipsLevelInfo[0].currency}
                  tipsLevelInfo={tipsLevelInfo}
                />
              )}
            </WithProgressBar>

            {tipsLevelInfo.slice(1).map((tip) => {
              const { targetRate, textHtml } = tip
              return (
                <CurrentInterest key={uniqueId(tip.textHtml)}>
                  <TopContent>
                    <TitleText>{targetRate}% годовых</TitleText>
                  </TopContent>
                  <SecondaryText
                    dangerouslySetInnerHTML={{
                      __html: textHtml,
                    }}
                  />
                </CurrentInterest>
              )
            })}
          </>
        ) : (
          <></>
        )}

        <ConditionsWrapper>
          <TopContent>
            <TitleText>Подробные условия по ставкам</TitleText>
          </TopContent>
          <Conditions>
            {additionalInfo?.map((info) => {
              const { textHtml } = info
              return (
                <ConditionItem
                  key={uniqueId(info.textHtml)}
                  dangerouslySetInnerHTML={{ __html: textHtml }}
                />
              )
            })}
          </Conditions>
        </ConditionsWrapper>
      </DialogContainer>
    </BaseModal>
  )
}
