import React from 'react'
import DefaultPage from '@/layouts/DefaultPage'
import { AccountPage } from '@/containers/AccountPage'
import { AppPage } from '@/models/AppPage'
import { TransfersModal } from '@/features/transfers-modal'

const Page: AppPage = () => {
  return (
    <DefaultPage hideMobileHeader>
      <AccountPage />
      <TransfersModal />
    </DefaultPage>
  )
}

export default Page
