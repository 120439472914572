import React from 'react'
import { observer } from 'mobx-react-lite'
import { Slider, SliderShimmer } from '@/components/Slider'
import { color } from '@platform-ui/core'
import { CurrencyType } from '@/models/CurrencyType'
import { ProductsModel } from '@/api/products/products'
import { formatDate } from '@/utils/formatDate'
import { SliderInfoSubTitle, SliderInfoTitle, SliderInfoWrapper } from './styled'

interface DepositDetailsSliderProps {
  isLoading: boolean
  amount: number
  interestRate: number
  currencyType: CurrencyType
  maturityDate: string
  contractStatus: ProductsModel.ContractStatus
}

export const DepositDetailsSlider = observer(
  ({
    isLoading,
    amount,
    interestRate,
    currencyType,
    maturityDate,
    contractStatus,
  }: DepositDetailsSliderProps) => {
    if (isLoading) {
      return <SliderShimmer />
    }

    const interestRateDisplayed = interestRate.toString().replace('.', ',')

    /**
     * ContractStatusEnum
     * @see https://confluence.homecredit.ru/confluence/pages/viewpage.action?pageId=92970609#API-GET/deposits-ContractStatusEnum
     */
    const getContractStatusDetails = (): string | null => {
      switch (contractStatus) {
        case ProductsModel.ContractStatus.Active:
          return `до ${formatDate(new Date(maturityDate), 'DD MMMM YYYY')}`
        case ProductsModel.ContractStatus.Closed:
          return `Закрыт ${formatDate(new Date(maturityDate), 'DD MMMM YYYY')}`
        case ProductsModel.ContractStatus.ScheduledToClose:
          return 'Будет закрыт завтра'
        default:
          return null
      }
    }

    return (
      <>
        <Slider bgColor={color['products/deposit']} amount={amount} currencyType={currencyType}>
          <SliderInfoWrapper>
            <SliderInfoTitle>Процентная ставка</SliderInfoTitle>
            <SliderInfoSubTitle>{interestRateDisplayed}% годовых</SliderInfoSubTitle>
          </SliderInfoWrapper>

          {contractStatus !== ProductsModel.ContractStatus.Unknown && getContractStatusDetails() && (
            <SliderInfoWrapper>
              <SliderInfoTitle>Срок вклада</SliderInfoTitle>
              <SliderInfoSubTitle>{getContractStatusDetails()}</SliderInfoSubTitle>
            </SliderInfoWrapper>
          )}
        </Slider>
      </>
    )
  }
)
