import styled from '@emotion/styled'
import { ThemeColor } from '@emotion/react'
import { Button } from '@platform-ui/components'
import { ButtonBase } from '@platform-ui/components/ButtonBase'

export const TabListWrapper = styled.div<{ isSimpleHint: boolean }>(({ theme, isSimpleHint }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  background: isSimpleHint ? 'none' : theme.color['surface/secondary'],
  padding: '16px',
  borderRadius: '12px',

  [`${theme.media.md}`]: {
    padding: '20px',
    borderRadius: '12px',
  },
}))

export const TextAdditional = styled.p(({ theme }) => ({
  ...theme.typography['body/12'],
  marginBottom: theme.spacing.s8,
  color: '#797F90',
  cursor: 'default',

  [`${theme.media.md}`]: {
    ...theme.typography['headline/16'],
    marginBottom: theme.spacing.s8,
  },
}))

export const TextSecondary = styled.p(({ theme }) => ({
  ...theme.typography['headline/16'],
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  maxWidth: 600,
  overflow: 'auto',
  color: 'inherit',
  cursor: 'default',
  gap: '16px',
  p: {
    margin: 0,
  },
  [`${theme.media.md}`]: {
    ...theme.typography['headline/16'],
    fontWeight: 500,
  },
}))

export const BottomContent = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
}))
export const TopContent = styled(ButtonBase)(({ theme }) => ({
  marginBottom: 8,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  background: 'inherit',
  width: '100%',
  padding: '2px 0',

  [`${theme.media.md}`]: {
    marginBottom: 8,
  },
}))

export const DateInfoIcon = styled.div<{ bgColor: ThemeColor }>(({ theme, bgColor }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 'none',
  width: '40px',
  height: '40px',
  marginRight: '16px',
  fontSize: 0,
  borderRadius: '50%',
  background: theme.color[bgColor],
}))

export const SignButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '56px',
  fontSize: '14px',
  whiteSpace: 'nowrap',
  borderRadius: '8px',
  margin: '16px 0 0',
  color: theme.color['text/primary'],
  background: theme.color['surface/main'],
}))

export const TopLeftContent = styled.div(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  gap: 8,
}))

export const TooltipText = styled.p(({ theme }) => ({
  ...theme.typography['headline/14'],
  padding: '12px 16px',
  maxWidth: 210,
  color: theme.color['text/tertiary'],
}))

export const TooltipIcon = styled.div(({ theme }) => ({
  margin: '0 0 2px 2px',
  backgroundColor: theme.color['icons/secondary'],
  borderRadius: '50px',
  color: theme.color['text/tertiary'],
}))
