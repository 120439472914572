import React from 'react'
import { useRouter } from '@/next//router'
import { ContentError } from '@/components/ContentError'
import { ApiStatus } from '@/core'
import { ProductsModel } from '@/api/products/products'
import { DepositPage } from './DepositPage/DepositPage'
import { SavingAccountPage } from './SavingAccountPage/SavingAccountPage'
import { useStockpilingProducts } from '@/features/product-lists/hooks/useStockpilingProducts'
import { useWidetsSettingsStore } from '@/features/product-lists/widgetsSettingsStore/WidgetsSettingsStore'

export const AccountPage = () => {
  const router = useRouter()
  const productId = router.query.productId as string
  // запрашиваем accounts и deposits для получения идентификатора для осуществления запроса данных по конкретному продукту
  const { products, status } = useStockpilingProducts()
  const widgetsSettings = useWidetsSettingsStore()
  const widgetsSettingsMap = widgetsSettings.getSettingsMap

  const productById = products[productId]

  const isLoading = status === ApiStatus.Pending
  const isServerError = status === ApiStatus.Rejected
  const isProductNotFound = status === ApiStatus.Fulfilled && !productById

  const isDepositProduct =
    productById && productById.productType === ProductsModel.ProductType.Deposit
  const isAccountProduct =
    productById && productById.productType === ProductsModel.ProductType.Account

  if (!isServerError && !isProductNotFound && !isLoading) {
    if (isDepositProduct) {
      return (
        <DepositPage
          productId={productId}
          contractUniqueId={productById.contractUniqueId}
          changedProductName={widgetsSettingsMap[productById.contractUniqueId]?.changedProductName}
        />
      )
    }
    if (isAccountProduct) {
      return <SavingAccountPage />
    }

    return <ContentError />
  }

  return null
}
