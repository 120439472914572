import { useState } from 'react'
import { toast } from 'react-toastify'
import { DocsIcon } from '@platform-ui/icons'
import { Body14 } from '@platform-ui/typography'
import { CircularProgress } from '@platform-ui/components'
import { DocumentWrapper } from '../styled'
import { getDocumentFile } from '@/api/products/getDocumentFile'
import { ResponseType } from '@/models/ResponseType'
import { GetDocumentsModel } from '@/api/products/getDocuments'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'

type DocumentButtonProps = {
  isLoading: boolean
  title: string
  contractNumber: string
  productType: GetDocumentsModel.DocumentProductType
  reportCode: string
}
export const DocumentButton: React.FC<DocumentButtonProps> = ({
  title,
  contractNumber,
  productType,
  reportCode,
}) => {
  const context = useAppContext<AppContext>()
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async () => {
    if (isLoading) {
      return
    }
    try {
      setIsLoading(true)
      const response = await getDocumentFile({ contractNumber, productType, reportCode })

      if (response?.data?.type === ResponseType.ResolveWithData) {
        const blob = new Blob([response.data.arrayBuffer], { type: 'application/pdf' })
        const linkUrl = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = linkUrl
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } catch (err) {
      context.toastify.error({
        title: 'Возникла ошибка при загрузке документа',
        description: 'Попробуйте еще раз, или свяжитесь с нами в чате',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <DocumentWrapper onClick={handleClick} needBorder>
      {isLoading ? <CircularProgress size={24} /> : <DocsIcon color="icons/primary" />}
      <Body14>{title}</Body14>
    </DocumentWrapper>
  )
}
