import React from 'react'
import { TabBar } from './containers/TabBar'
import { TabBarShimmer, InterestRate } from './components'
import { History } from '@/features/history'
import { color } from '@platform-ui/core'

export const AccountDetails = ({
  isLoading,
  accountNumber,
  contractNumber,
  lastFourCardDigits,
  productName,
  currencyType,
  productId,
  productById,
}) => {
  if (isLoading) {
    return <TabBarShimmer />
  }
  return (
    <>
      <TabBar productId={productId} />
      <InterestRate productById={productById} />
      <History
        productType="account"
        accountNumber={accountNumber}
        contractNumber={contractNumber}
        lastFourCardDigits={lastFourCardDigits}
        productName={productName}
        startContractDate="2021-02-01"
        bgColor={color['products/savingsNew']}
        currencyType={currencyType}
      />
    </>
  )
}
