import React from 'react'
import { MoreIcon } from '@platform-ui/icons'
import { ActionButton } from '@/models/ActionBar'
import { ActiveContent, ButtonsIds } from '@/features/deposits-details'
import { ActionButtonTitle, ActionIconWrapper, StyledActionBar, StyledActionButton } from './styled'

export type ActionBarProps = {
  mainButtons: ActionButton[]
  moreButtonAction?: (content: ActiveContent) => void
}

export const ActionBar: React.FC<ActionBarProps> = ({ mainButtons, moreButtonAction }) => {
  return (
    <StyledActionBar>
      {mainButtons?.map((button) => (
        <StyledActionButton
          key={button.id}
          onClick={() => button.action({ id: button.id, title: button.title })}
        >
          <ActionIconWrapper>{button.icon}</ActionIconWrapper>
          <ActionButtonTitle>{button.title}</ActionButtonTitle>
        </StyledActionButton>
      ))}
      {moreButtonAction && (
        <StyledActionButton onClick={() => moreButtonAction({ id: ButtonsIds.more, title: 'Ещё' })}>
          <MoreIcon color="icons/primary" />
          <ActionButtonTitle>Ещё</ActionButtonTitle>
        </StyledActionButton>
      )}
    </StyledActionBar>
  )
}
